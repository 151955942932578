




import Vue from 'vue';
import packageJson from '@/../package.json';

export default Vue.extend({
  computed: {
    getVersion() {
      return packageJson.version;
    },
  },
});
