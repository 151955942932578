import { render, staticRenderFns } from "./DisplayVersion.vue?vue&type=template&id=7a788faa&scoped=true&"
import script from "./DisplayVersion.vue?vue&type=script&lang=ts&"
export * from "./DisplayVersion.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a788faa",
  null
  
)

export default component.exports